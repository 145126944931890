import React from 'react';
import Header from 'cccisd-header';
import style from './style.css';
import Shopping from 'cccisd-icons/cart';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;
const Env = window.cccisd.env;
const wpUrl = Env.wpUrl || Appdefs.app.wpUrl;

const AppHeader = ({ className, noLogoLink }) => {
    const primaryNavLinks = [
        { url: wpUrl, label: 'Home' },
        { url: wpUrl + '/index.php/our-services', label: 'Our Services' },
        { url: '/interventions', label: 'On-Demand Courses' },
        { url: wpUrl + '/index.php/events', label: 'Events' },
        { url: '/resources_public', label: 'Resources' },
        { url: wpUrl + '/index.php/about-us/', label: 'About Us' },
        { url: wpUrl + '/index.php/cart/', label: '', icon: Shopping },
    ];

    const secondaryNavLinks = [
        { url: '/manage', label: 'Manage' },
        { url: '/quest', label: 'Data' },
    ];

    return (
        <Header
            containerClassName={`${className} ${style.headerWrapper}`}
            logoClassName={style.logo}
            primaryNavLinks={primaryNavLinks}
            secondaryNavLinks={Fortress.user.acting.role.handle === 'uberadmin' ? secondaryNavLinks : []}
            showAlertAction
            {...(noLogoLink ? { logoLink: null } : { logoLink: wpUrl })}
        />
    );
};

AppHeader.defaultProps = {
    className: 'container-fluid',
};

export default AppHeader;
